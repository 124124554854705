<template>
    <div class="MovingRingMonitoring">
        <div class="MovingRingMonitoring-T">
            <div class="MovingRingMonitoringL">
                <Breadcrumb></Breadcrumb>
            </div>
            <div class="MovingRingMonitoringR">
                <MovingModel :JsonArr="JsonArr" @confirm="confirmfun" @editstr="contextstrFun" @addstr="contextstrFun('添加设备')"></MovingModel>
                <!-- <el-button type="primary" icon="el-icon-plus" @click="contextstrFun('添加设备')">添加设备</el-button> -->
            </div>
        </div>
        <div class="MovingRingMonitoring-B">
            <el-tabs v-model="editableTabsValue" type="card" :closable="false" :addable="true"
                @tab-click="tabclick($event, editableTabsValue)"
                @contextmenu.prevent.native="contextmenuFun($event)"
                @edit="handleTabsEdit(editableTabsValue, $event)">
                <el-tab-pane v-for="item in groupList" :key="item.Id" :label="item.T_name" :Id="item.Id">
                    <MovingRingNoData v-if="JsonArr.length == 0"></MovingRingNoData>
                </el-tab-pane>
            </el-tabs>
            <div class="el-tab-pane-main" v-for="item, o in JsonArr" :key="o">
                <div class="el-tab-pane-main-tit"> {{ item.T_tab ? item.T_tab : '未定义名称' }} </div>
                <div class="el-tab-pane-main-conter">
                    <template v-for="item1, i in item.JsonArray">
                        <div class="el-tab-pane-main-conter-item"  v-for="itemlist, i in item1.T_dataJson"
                            @click="setState(itemlist)"
                            v-show="returnBoolean(itemlist.d_show)"
                            >
                            <div class="el-tab-pane-main-conter-item-b">
                                {{ itemlist[itemlist.names]=='' || itemlist[itemlist.names]==undefined ?'未定义名称':itemlist[itemlist.names]}}
                            </div>
                            <div class="el-tab-pane-main-conter-item-t">
                                <div class="el-tab-pane-main-conter-item-t1">
                                    <div class="el-tab-pane-main-conter-item-t2" 
                                    :class="itemlist.T_online != 1 ? 't2-info' :
                                    itemlist[itemlist.swiperName] == 1 ? 't2-success':itemlist[itemlist.swiperName] == 0 ? 't2-danger':'t2-warning'">
                                    </div>
                                </div>
                                <p style="font-size: 12px;margin-top: 5px;">
                                    {{ itemlist.T_online != 1 ? '离线': itemlist[itemlist.swiperName]==1?'开启': itemlist[itemlist.swiperName]==0?'关闭':'执行失败'}}
                                </p>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <MovingRing ref="MovingRingRef" :value.sync="parentValue" @closefn="closed = true" @customEvent="handleCustomEvent" @parentValue="parentValueFun" @setClick="setClickFun">
        </MovingRing>
    </div>
</template>

<script>
import MovingRing from "./modules/MovingRingMonitoringDIR.vue";
import MovingRingNoData from "./modules/MovingRingNoData.vue";
import MovingModel from "./modules/MovingModel.vue";


import {
    getGroupList,
    getColdMonitorlist,
    setGroupAdd,
    getdeviceGetFun,
    setGroupDeviceAddFun
} from "./js/MovingRingApi.js";
// import {
//     init, close,
//     socket
// } from "./js/websocketfun.js";
import { MovingFun, arrMov } from "./js/MovingData.js";
export default {
    name: "",
    components: { MovingRing, MovingRingNoData ,MovingModel},
    filter:{
        filterTab(){
            // console.log('筛选')
        }
    },
    data() {
        return {
            iswebSocket:true,//是否调用websocket ，false不调用
            returnData: true,//终止循环
            textData: '离线',
            parentValue: '',
            editableTabsValue: '',
            ColdMonitorList: [],
            socket: null,
            reslutData: null,
            loadingRes: null,
            msgBool: true,
            isObj: null,

            msgBoolall:false,
            msgallindex:0,
            webArray:[],
            setIntTime:null,//心跳定时

            isMessage:'',
            timeoutId:null,
            arrayData:[],
            closed:false,

             //单控
             singleIndex:0,//单控次数
            singleControl:false,//单控状态
            singleData:{},//单控发送web的数据格式


            groupList:[],//组
            JsonArr:[],//设备列表
            singleData:{},//单控发送web的数据格式
            IfwebSocket:false,//判断是否发送成功

            sendData:[],//当前需要全开全关的数组
            msgTime:null,//定时器
            thisIndex:0,//当前发送的数据索引
            iswebData:null,//当前正在进行发送的数据
        }
    },
    mounted() {
        // 初始化
        this.getGroupListApi()
        this.getgetGroupListApi()//获取设备
        
        /**
         * 定时器心跳 2分钟一次心跳
         */
        this.setIntTime = setInterval(()=>{
            let arrData = []
            this.ColdMonitorList.forEach(item=>{
                arrData.push(item.arrs[0])
            })
            arrData.forEach(j=>{
                j.T_dataJson.forEach(k=>{
                    let obj = {
                        type: 0,
                        sn:k.T_sn,
                        pass:j.T_password,
                        json: {},
                    }
                    this.send(obj)
                })
            })

        },120000)
    },
    destroyed() {
        // 销毁websocket
        this.close()
        
    },
    watch: {
        JsonArr:{
            handler(newData,oldData){
                console.log('数据变化',newData,oldData,this.socket)
                if(oldData.length==0 && newData.length!=0){//老数据从0开始了可以订阅了
                    if(this.socket!=null){
                        this.close()//关闭socket
                    }
                    this.init()
                }
            },
            deep:true,
        },
        async IfwebSocket(newData){
            this.loadings = true
            let obj = JSON.parse(this.sendData.T_text)//当前需要控制的数据
            if (newData) {//进行中
                this.iswebData = obj[this.thisIndex]
                for (let j = 0; j < 3; j++) {
                    const setData = await this.setDataFn(obj[this.thisIndex])//处理发送到webSocket的数据格式
                    this.send(setData)
                    await new Promise(resolve => this.msgTime = setTimeout(resolve, 3000));//3s
                    if(j==2 && newData==true){//执行失败，进入下一次
                        this.IfwebSocket = false
                        clearTimeout(this.msgTime)
                        this.msgTime = null
                        this.$refs.MovingRingRef.IsTableData.push({name:this.iswebData[this.iswebData.names]==undefined?'未定义名称':this.iswebData[this.iswebData.names],booles:false})
                    }
                }
            }else{//完成
                if(this.thisIndex < obj.length-1){//小于还未发完
                    this.IfwebSocket = true
                    this.thisIndex += 1
                }else{//发送完成改成0
                    this.thisIndex = 0
                    this.$refs.MovingRingRef.loading = false
                    this.iswebData = null
                }
                clearTimeout(this.msgTime)
                this.msgTime = null
            }
        },
        async singleControl(newData){
            if(newData){//进行中
                for (let j = 0; j < 3; j++) {
                    this.send(this.singleData)
                    this.singleIndex=j
                    await new Promise(resolve => this.msgTime = setTimeout(() => resolve(),3000))
                    if(j==2 && newData==true){//执行失败，进入下一次
                        this.$message.error('指令执行失败')
                        this.singleControl = false
                    }else{
                        continue
                    }
                }
            }else{//成功
                this.singleControl = false
                if(this.singleIndex==2){
                    this.$message.error('执行失败')
                }else{
                    this.$message.success('执行成功')
                }
                clearTimeout(this.msgTime)
                this.msgTime = null
                this.singleIndex = 0
                this.loadingRes.close()
            }
        },
    },
    methods: {
        returnBoolean(value){ 
            // console.log('筛选',value) 
            if(value=="true" || value==true){
                return Boolean(true)
            }else{
                return Boolean(false)
            }
        },
        confirmfun(){
            console.log('确定')
            this.getgetGroupListApi()//获取设备
        },
        async getGroupListApi(){//获取控制组
            const reslut = await getColdMonitorlist({})
            this.groupList = reslut
        },
        async getgetGroupListApi(){//获取设备列表
            const reslut = await getGroupList({})
            let arr = [...reslut]
            let setArr = []
             this.loadingRes = this.$loading({
                lock: true,
                text: '正在加载，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            for (let i = 0; i < arr.length; i++) {
                let isArray = {...arr[i],JsonArray:[]}
                // console.log('一级',isArray)
                for (let j = 0; j < arr[i].T_sn.length; j++) {
                    const res = await this.getdeviceGetFunApi(arr[i].T_sn[j])
                    if(res.Code==200){
                        isArray.JsonArray.push(res.Data)
                    }
                }
                // console.log('获取设备列表1111',isArray)
                if(isArray.JsonArray.length!=0)setArr.push(isArray)
            }
            this.loadingRes.close()
            this.JsonArr = setArr
        },
        async getdeviceGetFunApi(sn){//通过sn循环获取设备
            return new Promise(resolve=>{
                getdeviceGetFun({T_sn:sn}).then(res=>{
                    setTimeout(() => {
                        if (res.Code==200) {
                            const reslut = MovingFun(res)
                            // const j = reslut.Data.T_dataJson.filter((item)=>item.d_show)
                            // console.log('处理',reslut)
                            // reslut.Data.T_dataJson = j
                            reslut.Data.T_dataJson = reslut.Data.T_dataJson

                            resolve(reslut)
                        }else{
                            resolve(res)
                        }
                    }, 100);
                })
            })

        },
        setState(item){//单个控制-发送指令
            console.log('单控',item)
            this.loadingRes = this.$loading({
                lock: true,
                text: '正在发送控制指令，请稍后...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.singleControl = true //单控发送中状态
            this.singleData = {
                type: 3,
                sn: item.T_sn,
                pass: item.T_password,
                json: {},
            }
            var obj2 = {}
            this.$set(obj2, item.swiperName, item[item.swiperName] == 1 ? 0 : 1)
            this.$set(this.singleData.json, item.keys, obj2)
            
        },

        /**
         * 点击全控
         * @param {*} targetName 
         */
         tabclick(event, targetName) {
            console.log('点击全控',this.groupList[targetName])
            this.sendData = this.groupList[targetName]
            this.$refs.MovingRingRef.DialogTitles = event.label
            this.$refs.MovingRingRef.centerDialogVisible = true
        },
        async setClickFun() {//已经确定全控制了
            this.IfwebSocket = true//改变发送数据状态
        },

        addEquipment(name,it,obj) {//添加或者编辑触发按钮
            this.stops = true
            this.titleBox = name
            this.isObjket = obj
            let isArr = [...this.JsonArr]
            if(name=='编辑设备'){
                const b = isArr.find(item => item.T_sn[0]==obj.T_sn)
                this.form.T_sn = obj.T_sn
                this.form.T_tab = b.T_tab
                this.$set(this.form,obj.names, obj[obj.names]==undefined?'':obj[obj.names])
            }else{
                Object.keys(this.form).forEach(item=>{
                    this.form[item] = ''
                })
            }
        },
        contextstrFun(tit, tab) {//右击编辑
            console.log('编辑',this.$refs.MovingRingRef.form)
            this.$refs.MovingRingRef.DialogTitles = tit
            this.$refs.MovingRingRef.addOuterVisible = true
            if (tit == '编辑设备') {
                this.$refs.MovingRingRef.showDel = true
                // this.$refs.MovingRingRef.form.Id = tab.T_ProductID
                this.$refs.MovingRingRef.form.T_sn = tab.T_sn
                this.$refs.MovingRingRef.form.T_tab = tab.T_tab
                // this.$refs.MovingRingRef.form.d_name = tab[tab.names]
                // this.$refs.MovingRingRef.arrs = tab
                // this.$refs.MovingRingRef.arrs = {...tab,T_password:datas.JsonData.T_password}
            } else {
                this.$refs.MovingRingRef.showDel = false
                // this.$refs.MovingRingRef.form.T_sn = ''
                // this.$refs.MovingRingRef.form.T_tab = ''

            }
        },


        setDataFn(key){//处理传socket的数据格式
            return new Promise(reslove=>{
                let obj = {
                    type: 3,
                    sn: key.T_sn,
                    pass: key.pass || key.T_password,
                    json: {},
                }
                var obj2 = {}
                this.$set(obj2, key.swiperName, key[key.swiperName])
                this.$set(obj.json, key.keys, obj2)
                reslove(obj)
            })
        },
        handleCustomEvent(data) {
            this.send(data);
            this.iswebSocket = false
            this.msgallindex = 0
        },
       
        setwarningFn() {//执行失败把当前设置黄色并且显示执行失败
            this.ColdMonitorList.forEach(item => {
                let isArr = item.arrs[0].T_dataJson
                isArr.forEach(isItem => {
                    if (isItem.T_sn == this.isObj.sn && isItem.keys == Object.keys(this.isObj.json)[0]) {
                        console.log('渲染', isItem)
                        isItem[isItem.swiperName] = 888
                    }
                })
            })

            console.log('**', this.ColdMonitorList)
        },
       
        getDeviceGetsApi(sn) {
            return new Promise((resolve, reject) => {
                getdeviceGetFun({ T_sn: sn }).then(res => {
                    // res.Data.selection = false
                    if (res.Code == 200) {
                        resolve(res)
                        this.returnData = true
                    } else {
                        resolve(res)
                        this.returnData = false
                    }
                })
            })
        },
        //更新设备，更新组
        parentValueFun(targetName) {
            if(targetName){
                this.getGroupListApi()//获取控制组
            }else{
                this.getgetGroupListApi()//获取设备
            }
        },
        /**
         * 右击
         * @param {*} event 
         */
        contextmenuFun: async function (event) {
            const reslut = this.groupList.find(item => item.T_name == event.target.innerText)
            if (reslut) {
                this.$refs.MovingRingRef.formData.T_name = event.target.innerText
                this.$refs.MovingRingRef.formData.Id = reslut.Id
                this.$refs.MovingRingRef.outerVisible = true
                this.$refs.MovingRingRef.DialogTitles = reslut.T_name
                this.$refs.MovingRingRef.showDel = true

                let arr = JSON.parse(reslut.T_text)
                this.$refs.MovingRingRef.tableData = await this.setTableDatafun()
                this.$refs.MovingRingRef.selectTableData = JSON.parse(reslut.T_text)
            }
        },

        

        /**
         * 添加组
         */
        async handleTabsEdit(value, event) {
            this.$refs.MovingRingRef.outerVisible = true
            this.$refs.MovingRingRef.DialogTitles = '添加组'
            this.$refs.MovingRingRef.showDel = false
            this.$refs.MovingRingRef.tableData = await this.setTableDatafun()
            this.$refs.MovingRingRef.selectTableData = []
            this.$refs.MovingRingRef.formData.T_name = ''
        },
        setTableDatafun() {
            return new Promise(resolve => {
                let arr = [...this.JsonArr]
                let arrTable = []
                arr.forEach(item1 => {
                    item1.JsonArray.forEach(item2 => {
                        item2.T_dataJson.forEach(item3 => {
                            item3.selection = false
                            arrTable.push(item3)
                        })
                    })
                })
                resolve(arrTable)
            })
        },
        




        /************************************连接websocket */
        //websocket
        init() {
            if (typeof (WebSocket) === "undefined") {
                alert("您的浏览器不支持socket")
            } else {
                // 实例化socket
                this.socket = new WebSocket("wss://cold.coldbaozhida.com/Cold_Monitor_AOIT/WS")
                // this.socket = new WebSocket("wss://uat.sssyin.cn/ws-reservation")
                // 监听socket连接
                this.socket.onopen = this.open
                // 监听socket错误信息
                this.socket.onerror = this.error
                // 监听socket消息
                this.socket.onmessage = this.getMessage
                //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                window.onbeforeunload = () => {
                    this.socket.onclose = this.close
                };
                return this.socket
            }
        },
        open() {
            let that = this
            this.loadingRes = this.$loading({
                lock: true,
                text: '正在订阅中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            console.log('订阅',this.JsonArr)
            for (let i = 0; i < that.JsonArr.length; i++) {
                // console.log('开始连接1',that.ColdMonitorList[i])
                for (let j = 0; j < that.JsonArr[i].JsonArray.length; j++) {
                    // console.log("socket连接成功",that.JsonArr[i].JsonArray[j])
                        that.send({
                            type: 1,
                            sn: that.JsonArr[i].JsonArray[j].T_sn,
                            pass: that.JsonArr[i].JsonArray[j].T_password,
                            json: {}
                        })
                }
            }
            this.loadingRes.close()
        },

        error(error) {
            console.log("连接错误", error)
            if (this.socket) this.close()
            this.init()
        },

        getMessage(msg) {
            // 普通推送——正常处理
            let reslut = JSON.parse(msg.data)
            let self = this
            console.log('接收消息11',reslut)
            if(reslut.T_sn!=undefined){//返回消息是控制后返回的数据
                if(reslut.msg && reslut.msg.includes('设备主动上线')) return
                let isArr = [...this.JsonArr]
                if (reslut.T_sn!=undefined) {
                    //单控
                    if(this.singleControl){//单控进行中
                        if(this.singleData.sn==reslut.T_sn){
                            this.singleControl = false
                        }
                    }
                    
                    //全开全关时改变状态
                    if (this.iswebData!=null) {
                        if( reslut.T_sn==this.iswebData.T_sn){
                            this.IfwebSocket = false
                            clearTimeout(this.msgTime)
                            this.msgTime = null
                            this.$refs.MovingRingRef.IsTableData.push(//全控成功
                                {name:this.iswebData[this.iswebData.names]==undefined?'未定义名称':this.iswebData[this.iswebData.names],booles:true})
                        }
                    }
                }
                // for (let index = 0; index < isArr.length; index++) {//处理更名之后没有名称
                //     if(isArr[index].T_sn[0]==reslut.T_sn){
                //         for (const key in reslut) {
                //             let setArr = isArr[index].JsonData.T_dataJson
                //             for (let p = 0; p < setArr.length; p++) {
                //                 if(key==setArr[p].keys){
                //                     this.$set(reslut[key],setArr[p].names,setArr[p][setArr[p].names])
                //                     reslut[key].d_show = setArr[p].d_show
                //                 }
                //             }
                //         }
                //         break;
                //     }
                // }

                for (let i = 0; i < isArr.length; i++) {
                    let subArr = isArr[i].JsonArray
                    // console.log('一级',subArr)
                    for (let j = 0; j < subArr.length; j++) {
                        if(subArr[j].T_sn==reslut.T_sn){
                            let data = {
                                Data:{...subArr[j]}
                            }
                            data.Data.T_dataJson = reslut
                            let setdatas = MovingFun(data)

                            let arr1 = [...this.JsonArr[i].JsonArray[j].T_dataJson]
                            let arr2 = [...setdatas.Data.T_dataJson]


                            for (let g = 0; g < arr1.length; g++) {
                                for (let h = 0; h < arr2.length; h++) {
                                    if(arr2[h].keys == arr1[g].keys){
                                        arr2[h].d_show = arr1[g].d_show
                                        arr2[h][arr2[h].names] = arr1[g][arr1[g].names]
                                    }
                                } 
                            }
                            setdatas.Data.T_dataJson = [...arr2]

                            self.JsonArr[i].JsonArray[j].T_dataJson = setdatas.Data.T_dataJson


                        }
                    }
                }
                // const b = isArr.find(item => item.T_sn[0]==reslut.T_sn)
                // console.log('web返回',b)
                // b.JsonData.T_dataJson = reslut
                // let data = {
                //     Data:b.JsonData
                // }
                
                // const j = MovingFun(data)
                // const index = isArr.findIndex(item => item.T_sn[0] == j.Data.T_sn)
                // j.Data.T_dataJson = j.Data.T_dataJson.filter((item)=>item.d_show)

                // this.JsonArr[index].JsonData = j.Data
            }else{
                // console.log('其他消息',this.JsonArr)
            }
            this.loadingRes.close()

        },

        // 发送消息给被连接的服务端
        send(params) {
            // console.log('发送消息', params)
            this.socket.send(JSON.stringify(params))
        },

        /**
         * 关闭socket
         */
        close() {
            console.log("socket已经关闭",this.socket)
            clearInterval(this.setIntTime)
            if (this.socket) {
                this.socket.onclose = close
                this.socket.onopen = open
            }
        }

    }
}
</script>
<style scoped>
::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: none;
    user-select: none;
    min-height: 47px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-bottom-color: #409eff;
}

::v-deep.el-tabs__item.is-active {
    color: #303133;
}

::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item {
    color: #fff;
    background-color: #409eff;
    font-size: 14px;
    border-radius: 4px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 5px;
}

::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item:hover {
    background: #3a91ea;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active:hover {
    border-bottom-color: #4499f1;
}

::v-deep .el-tabs__new-tab {
    border: 1px solid #3a91ea;
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 18px;
    outline: none;
}

::v-deep .el-tabs__new-tab:hover {
    border: 1px solid #67c23a;
}

::v-deep .el-tabs__new-tab>.el-icon-plus {
    color: #3a91ea;
}
</style>
<style lang="scss">
.MovingRingMonitoring-T {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(#fff, 1);
    height: 70px;
    padding: 15px 10px;
    box-shadow: 5px 5px 10px -10px #000;

    .MovingRingMonitoringL {
        display: flex;
        align-items: center;
    }

    .MovingRingMonitoringR {
        display: flex;
        align-items: center;
        cursor: pointer;
    }
}

.MovingRingMonitoring-B {
    background: rgba(#fff, 1);
    margin: 10px;
    padding: 20px 30px;
    color: #000;

    .el-tab-pane-main {
        margin-bottom: 40px;

        .el-tab-pane-main-tit {
            margin-bottom: 10px;
            color: #909399;
            font-weight: 500;
            font-size: 14px;
        }

        .el-tab-pane-main-conter {
            //display: grid;
            //grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
            grid-gap: 20px;
            display: flex;
            flex-wrap: wrap;
        }


        .el-tab-pane-main-conter-item {
            border: 1px solid #E4E7ED;
            min-height: 150px;
            min-width: 150px;
            border-radius: 4px;
            cursor: pointer;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            position: relative;

            .el-tab-pane-main-conter-item-b {
                width: 100%;
                height: 40px;
                border-bottom: 1px solid #E4E7ED;
                background: #E4E7ED;
                text-align: center;
                line-height: 40px;
                font-size: 12px;
            }

            .el-tab-pane-main-conter-item-t {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .el-tab-pane-main-conter-item-t1 {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    box-shadow: 0 0 10px 2px #E4E7ED inset;

                    .el-tab-pane-main-conter-item-t2 {
                        width: 20%;
                        height: 20%;
                        border-radius: 50%;
                    }


                }
            }


        }

        .el-tab-pane-main-conter-item:hover {
            box-shadow: 0 0px 12px 0 rgba(0, 0, 0, .1);
            // border:none;
        }

    }

    .t2-success {
        background: #409EFF;
    }

    .t2-info {
        background: #909399;
    }

    .t2-warning {
        background: #e6a23c;
    }

    .t2-danger {
        background: #f56c6c;
    }
}
</style>
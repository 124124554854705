<template>
    <div>
        <el-dialog :title="DialogTitles" :visible.sync="outerVisible" :append-to-body="true">
            <el-form ref="dynamicValidateForm" :model="formData" label-width="80px">
                <el-form-item label="组名称" prop="T_name" :rules="[{ required: true, message: '请输入组名称', trigger: 'blur' }]">
                    <el-input v-model="formData.T_name"></el-input>
                </el-form-item>
                <el-form-item label="控制设备">
                    <el-button icon="el-icon-plus" @click="addinnerVisible">添加设备</el-button>
                </el-form-item>
                <el-form-item label="">
                    <el-table :data="selectTableData" ref="multipleTable" style="width: 100%" max-height="350" border>
                        <el-table-column label="设备名称" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div> {{ scope.row[scope.row.names] }} </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="开关">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row[scope.row.swiperName]" active-color="#13ce66"
                                    inactive-color="#ff4949" :active-value="1" :inactive-value="0">
                                </el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button @click="delClick(scope.row, scope.$index)" type="text"
                                    size="small">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <el-dialog title="添加设备" :visible.sync="innerVisible" append-to-body>
                <!-- :row-class-name="(row)=>row.T_dataJson==null?'hidden-row':''" 隐藏行 -->
                <el-table ref="multipleTable" :data="tableData" :key="Math.random()" tooltip-effect="dark" style="width: 100%" max-height="450" border>
                    <el-table-column width="55">
                        <template slot="header" slot-scope="scope">
                            <el-checkbox v-model="selectAllData" @change="selectAll(selectAllData)"></el-checkbox>
                        </template>
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.selection" @change="selectis(scope)"></el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column label="设备名称" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div> {{ scope.row[scope.row.names] }} </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisible = false">取 消</el-button>
                <el-button type="danger" @click="setDate" v-if="showDel">删除</el-button>
                <el-button type="primary" @click="onSubmit('dynamicValidateForm')">提交</el-button>
            </div>
        </el-dialog>

        <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center append-to-body :close-on-click-modal="false">
            <div style="text-align: center;">正在操作，<span style="color: red;">{{ DialogTitles }}</span>，是否执行？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="setClick">立即执行</el-button>
            </span>
            <el-dialog :title="'批量操作【'+ DialogTitles +'】'" :visible.sync="DialogVisible" width="50%" center append-to-body destroy-on-close :close-on-click-modal="false" @close="closefn">
                <el-table :data="IsTableData" :key="Math.random()" ref="multipleTable" v-loading="loading" style="width: 100%" border>
                    <el-table-column label="设备名称" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="结果">
                        <template slot-scope="scope">
                            <p :style="scope.row.booles?succStyle:divStyle">
                                {{scope.row.booles?'成功':'失败'}}
                            </p>
                            <!-- <i v-if="scope.row.name == 1" class="el-icon-check" style="color: #409eff;font-size: 20px;"></i>
                            <i v-else class="el-icon-close" style="color: red;font-size: 20px;"></i> -->
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
        </el-dialog>

        <el-dialog :title="DialogTitles" :visible.sync="addOuterVisible" :append-to-body="true" width="30%">
            <el-form :model="form" ref="ruleForm" :rules="rules" label-width="80px">
                <el-form-item label="设备编号" prop="T_sn">
                    <el-input v-model="form.T_sn" :disabled="DialogTitles == '编辑设备' ? true : false"></el-input>
                </el-form-item>
                <!-- <el-form-item label="设备名称" prop="d_name" v-if="showDel">
                    <el-input v-model="form.d_name"></el-input>
                </el-form-item> -->
                <el-form-item label="设备标签" prop="T_tab">
                    <el-input v-model="form.T_tab"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addOuterVisible = false">取 消</el-button>
                <!-- <el-button type="danger" @click="setDelete" v-if="showDel">删除</el-button> -->
                <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </span>
        </el-dialog>
        <dialog></dialog>
    </div>
</template>

<script>
import {
    GroupDeviceAdd,
    GroupDevicePu,
    GroupDeviceDel,
    GroupAdd,
    Groupdel,
    GroupPu
} from "../../../api/MovingRingMonitoring/index.js";

export default {
    name: "",
    data() {
        return {
            loading: true,
            divStyle: {  
                color: 'red',  
            },
            succStyle: {  
                color: '#409EFF',  
            },
            infoStyle: {  
                color: '#e6a23c',  
            },
            outerVisible: false,
            innerVisible: false,
            centerDialogVisible: false,
            DialogVisible: false,
            DialogTitles: '',
            addOuterVisible: false,
            showDel: false,//是否显示删除按钮
            form: {
                Id: '',
                T_sn: '',//设备编号
                T_tab: '',//分组名称
                d_name: '',//设备名称
            },
            arrs:[],
            formData: {
                Id: null,
                T_name: '',
                T_text: []
            },
            selectAllData: false,//全选
            selectTableData: [],
            tableData: [],
            IsTableData : [],
            multipleSelection: [],
            rules: {
                T_sn: [
                    { required: true, message: '请输入设备编号', trigger: 'blur' }
                ],
                T_tab: [
                    { required: true, message: '请输入设备标签', trigger: 'blur' }
                ],
                d_name: [
                    { required: true, message: '请输入设备名称', trigger: 'blur' }
                ],
            }
        }
    },
    watch: {
        outerVisible(val){
            // if(!val){
            //     this.selectAllData = false
            //     this.tableData.forEach(item => {
            //         item.selection = false
            //     })
            // }
        },
        DialogTitles: { //监听的对象
            deep: true, //深度监听设置为 true
            handler: function (newV) {
                if (newV == '添加设备') {
                    this.$nextTick(()=>{
                        Object.keys(this.form).forEach(item => {
                            this.form[item] = ''
                        })
                    })
                }
                this.$refs.ruleForm != undefined ? this.resetForm('ruleForm') : ''
            }
        },
    },

    methods: {
        closefn(){
            this.$emit('closefn')
        },
        setClick(){
            this.loading = true
            this.DialogVisible = true
            this.centerDialogVisible = false
            this.IsTableData = []
            this.$emit('setClick')
        },
        setDate() {
            Groupdel({ Id: this.formData.Id }).then(res => {
                if (res.data.Code == 200) {
                    this.outerVisible = false
                    this.$emit('parentValue','zu')
                    this.$message.success('删除成功')
                }
            })
            // this.$emit('update:value', 123);
            // this.$emit('parentValue', 55555);
        },
        //删除
        setDelete() {
            GroupDeviceDel({ Id: this.form.Id }).then(res => {
                if (res.data.Code == 200) this.$emit('parentValue')
            })
        },
        //删除
        delClick(row, index) {
            this.selectTableData.splice(index, 1);
            const j = this.tableData.findIndex(item => item.T_sn == row.T_sn && item.d_name == row.d_name && item.keys == row.keys)
            this.tableData[j].selection = false
        },
        //全选
        selectAll(all) {
            this.tableData.forEach((item,index) => {
                item.selection = all
            })
            const reslut = this.tableData.filter(item=> {return item.selection==true})
            this.selectTableData =  reslut
            // if(all){
            //     this.selectTableData =  this.mergeAndDeduplicate(this.selectTableData, this.tableData)
            // }else{
            //     this.selectTableData =  []
            // }
        },
        // mergeAndDeduplicate(a, b) {  
        //     // 创建一个 Set 来存储 a数组中的唯一值  
        //     const setA = new Set(a);  
        //     // 使用 filter 方法过滤 b 数组，只保留那些不在 a 数组中的值  
        //     const uniqueB = b.filter(item => !setA.has(item));  
        //     // 将 a 数组和过滤后的 b 数组合并  
        //     const merged = [...a, ...uniqueB];  
        //     // 返回合并后的数组  
        //     return merged;  
        // },
        selectis(is) {
            console.log('单选',this.tableData[is.$index].selection)
            if(this.tableData[is.$index].selection){
                this.selectTableData.push(this.tableData[is.$index])
            }else{
                const index = this.selectTableData.findIndex(item => item.T_sn==this.tableData[is.$index].T_sn && item.keys==this.tableData[is.$index].keys)
                this.selectTableData.splice(index, 1)
            }
            let reslut = this.tableData.every(item=>item.selection==true)
            this.selectAllData = reslut
        },
        //添加table默认选中,需要把两数组的不同项放入toggleRowSelection
        addinnerVisible() {
            this.innerVisible = true

            this.tableData.forEach(item=>{
                this.selectTableData.forEach(item1=>{
                    if(item.T_sn==item1.T_sn && item.keys==item1.keys){
                        item.selection = true
                    }
                })
            })
            let reslut = this.tableData.every(item=>item.selection==true)
            this.selectAllData = reslut
        },
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.selectTableData.length==0){
                        this.$message.error('请选择控制设备')
                        return
                    }
                    this.formData.T_text = []
                    this.selectTableData.forEach(item => {
                        let obj = {
                            "T_ch":item.keys,
                            "T_value":item[item.swiperName],
                            "T_sn":item.T_sn,
                        }
                        item = {...item,...obj}
                        this.formData.T_text.push(item)
                    })
                    // console.log('提交',this.formData,this.selectTableData)
                    this.formData.T_text = JSON.stringify(this.formData.T_text)
                    if(this.DialogTitles=='添加组'){
                        this.setGroupAddApi(this.formData)
                    }else{
                        this.setGroupPu(this.formData)
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        setGroupPu(obj){
            GroupPu(obj).then(res => {
                if (res.data.Code == 200) {
                    this.outerVisible = false
                    this.$emit('parentValue','zu')
                    this.$message.success('修改控制组成功')
                }
            })
        },
        setGroupAddApi(obj) {
            console.log('新增组---',obj)
            delete obj.Id
            GroupAdd(obj).then(res => {
                if (res.data.Code == 200) {
                    this.outerVisible = false
                    this.$emit('parentValue','zu')
                    this.$message.success('新增控制组成功')
                }
            })
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let obj = { ...this.form }
                    if (this.DialogTitles == '添加设备') {
                        delete obj.d_name
                        delete obj.Id
                        this.setGroupDeviceAdd(obj)
                    } else {
                        delete obj.Id
                        let objs = {
                            type: 3,
                            sn:this.form.T_sn,
                            pass:this.arrs.T_password,
                            json: {},
                        }
                        var obj2 = {}
                        this.$set(obj2, this.arrs.names, this.form.d_name)
                        this.$set(objs.json, this.arrs.keys, obj2)
                        this.$emit('customEvent', objs);
                        this.setGroupDevicePu(obj)
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //编辑设备
        setGroupDevicePu(obj) {
            GroupDevicePu(obj).then(res => {
                console.log('编辑设备', res)
                if (res.data.Code == 200) {
                    this.$emit('parentValue');
                    this.addOuterVisible = false
                }
            })
        },
        //添加设备
        setGroupDeviceAdd(obj) {
            GroupDeviceAdd(obj).then(res => {
                console.log('添加设备', res)
                if (res.data.Code == 200) {
                    this.$emit('parentValue');
                    this.addOuterVisible = false
                }else{
                    this.$message.error(res.data.Msg)
                }
            })
        },
        /**
         * 重置表单验证
         * @param {*} formName 
         */
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>
<style lang="scss">
/* @import url(); 引入css类 */
</style>